<template>
  <div class="row login w-100 h-100 m-0">
    <div class="col-12 my-auto p-0">
      <b-card class="form-signin text-center shadow m-auto">
        <b-img :src="logo" height="112" blank-color="#ffffff" class="mt-3 mb-4"></b-img>
        <form @submit.prevent="login" class="form-signin">
          <div class="form-group text-center">
            <button class="btn btn-primary shadow">Login</button>
          </div>
        </form>
      </b-card>
    </div>
  </div>
</template>

<script>
import { auth } from "@/helpers/auth";

export default {
  data () {
    return {
      account: null, 
      title: process.env.VUE_APP_TITLE,
      logo: require('../assets/logo_rgb.png')
    }
  },
  created () {
    if(this.account)
      auth.logout();
  },
  async mounted() {
    this.account = auth.getAccount();
    if (this.account) {
      this.$router.push('/')
    }
  },
  methods: {
    login() {
      auth.loginRedirect();
    },
  }
};
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 380px;
  background: #e2e2e2;
}
</style>
